import React, { createContext, useState } from 'react'
import Button, { ButtonStyle } from 'components/ui/Button'
import { Dialog } from '@headlessui/react'
import cn from 'classnames'

export type ConfirmArgs = {
  title?: string
  message: string | React.ReactElement
  okButton?: string
  cancelButton?: string
  albertsons?: boolean
  color?: ButtonStyle['color']
  size?: 'sm' | 'md' | 'lg'
}

type ConfirmCallbacks = {
  onCancel: () => void
  onConfirm: () => void
}

type ConfirmDialogContextType = {
  openConfirmation: (args: ConfirmArgs) => Promise<boolean>
  closeConfirmation: () => void
}

export const ConfirmDialogContext = createContext<ConfirmDialogContextType>({
  openConfirmation: async () => false,
  closeConfirmation: () => {
    // do nothing
  }
})

export const ConfirmDialogProvider: React.FC = ({ children }) => {
  const [open, setOpen] = useState<boolean>(false)
  const [confirmationArgs, setConfirmationArgs] = useState<ConfirmArgs | null>(
    null
  )
  const [
    confirmCallbacks,
    setConfirmationCallbacks
  ] = useState<ConfirmCallbacks | null>(null)
  const value = {
    openConfirmation: async (args: ConfirmArgs): Promise<boolean> => {
      return new Promise((resolve) => {
        setConfirmationArgs(args)
        setConfirmationCallbacks({
          onConfirm: () => {
            setOpen(false)
            resolve(true)
          },
          onCancel: () => {
            setOpen(false)
            resolve(false)
          }
        })
        setOpen(true)
      })
    },
    closeConfirmation: () => {
      setOpen(false)
    }
  }
  return (
    <ConfirmDialogContext.Provider value={value}>
      {children}
      {open && <ConfirmDialog {...confirmationArgs!} {...confirmCallbacks!} />}
    </ConfirmDialogContext.Provider>
  )
}

type ConfirmDialogProps = ConfirmArgs & ConfirmCallbacks

function ConfirmDialog({
  title = 'Are you sure?',
  message,
  okButton = 'Confirm',
  cancelButton = 'Cancel',
  color = 'primary',
  onCancel,
  onConfirm,
  albertsons = false,
  size = 'sm'
}: ConfirmDialogProps) {
  return (
    <Dialog
      aria-label={title}
      className="relative z-50"
      onClose={() => {}}
      open
    >
      <Dialog.Overlay
        className="fixed inset-0"
        style={{
          backgroundColor: 'rgba(0,0,0,0.53)'
        }}
      />
      <div className="fixed inset-0 z-50 w-screen overflow-y-auto">
        <div className="flex min-h-screen items-center justify-center p-4">
          <Dialog.Panel className="shadow-xl max-w-lg w-full">
            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4 flex items-start rounded-t-xl">
              <div className="mt-3 sm:mt-0 sm:ml-4 sm:text-left">
                <h3
                  className={cn('text-gray-900', {
                    'text-lg leading-6 font-medium': size === 'sm',
                    'text-3xl leading-10 font-bold': size === 'md'
                  })}
                >
                  {title}
                </h3>
                <div className="mt-2">
                  <p
                    className={cn('text-gray-500', {
                      'text-sm': size === 'sm',
                      'text-base': size === 'md'
                    })}
                  >
                    {message}
                  </p>
                </div>
              </div>
            </div>
            <div
              className={
                'px-4 py-3 sm:px-6 flex justify-end bg-gray-100 rounded-b' +
                '-xl'
              }
            >
              <div className="sm:w-auto w-1/2">
                <Button
                  color="secondary"
                  isAlbertsons={albertsons}
                  onClick={onCancel}
                  variant="outline"
                  fullWidth
                >
                  {cancelButton}
                </Button>
              </div>
              <div className="ml-3 sm:w-auto w-1/2">
                <Button
                  color={color}
                  isAlbertsons={albertsons}
                  onClick={onConfirm}
                  variant="contained"
                  fullWidth
                >
                  {okButton}
                </Button>
              </div>
            </div>
          </Dialog.Panel>
        </div>
      </div>
    </Dialog>
  )
}
